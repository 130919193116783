import { AxiosResponse } from 'axios'
import { MutationFunction, useMutation, useQueryClient } from '@tanstack/react-query'

import {
  Contact,
  ContactListItem,
  ContactParty,
  ContractFiltersRequest,
  ContractFiltersResponse,
  ContractLineItemsResponse,
  ContractReminder,
  ContractRequest,
  ContractResponse,
  ContractTypeListItemResponse,
  ContractTypeRequest,
  ContractTypeResponse,
  Finance,
  Organization,
  OrganizationListItem,
} from '@models/ApproveitAccounting'
import { AccountingKeys } from '../Keys'

import { useCreate, useDelete, useGetList, useGetOne, usePatch, useUpdate } from '../request'
import api from '@src/api/api'
import { generatePath } from 'react-router-dom'

const PATHS = {
  organizations: '/api/accounting/organizations',
  contracts: '/api/accounting/contracts',
  contractTypes: '/api/accounting/contracts/agreements-types',
  contractsReminder: '/api/accounting/contracts/reminder',
  contacts: '/api/accounting/:contactParty',
  contactItem: '/api/accounting/:contactParty/:id',
  contactFinDetails: '/api/accounting/:contactParty/:contactId/financial-details',
  contactFinDetailsItem: '/api/accounting/:contactParty/:contactId/financial-details/:id',
  useVendorExport: '/api/accounting/:contactParty/export',
  terminateContract: '/api/accounting/contracts/:id/terminate',
  filters: '/api/accounting/contracts/filters',
}

const getContactPartyUrlPart = (party: ContactParty) => {
  return party + 's'
}

export const useGetApproveitOrganizations = (enabled = true) =>
  useGetList<OrganizationListItem>([AccountingKeys.Organizations], PATHS.organizations, {
    query: {
      enabled,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  })

export const useGetApproveitOrganization = (id?: number | string) =>
  useGetOne<Organization>([AccountingKeys.Organizations, id], `${PATHS.organizations}/${id}`, {
    query: {
      enabled: !!id,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  })

export const useCreateApproveitOrganizations = () => {
  const cache = useQueryClient()
  return useCreate<Organization, Organization>(PATHS.organizations, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.Organizations])
      },
    },
  })
}
export const useUpdateApproveitOrganizations = (id?: number | null) => {
  const cache = useQueryClient()
  return usePatch<Partial<Organization>>(`${PATHS.organizations}/${id}`, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.Organizations])
      },
    },
  })
}
export const useDeleteApproveitOrganizations = () => {
  const cache = useQueryClient()
  return useDelete<string | number>((id) => `${PATHS.organizations}/${id}`, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.Organizations])
      },
    },
  })
}

export const useGetContacts = (enabled = true, contactParty: ContactParty = ContactParty.vendor) => {
  const path = generatePath(PATHS.contacts, {
    contactParty: getContactPartyUrlPart(contactParty),
  })
  return useGetList<ContactListItem>([AccountingKeys.Contacts, contactParty], path, {
    query: {
      enabled,
    },
  })
}
export const useGetContact = (id?: number | string | null, contactParty: ContactParty = ContactParty.vendor) => {
  const path = generatePath(PATHS.contactItem, {
    contactParty: getContactPartyUrlPart(contactParty),
    id: id || '',
  })
  return useGetOne<Contact>([AccountingKeys.Contacts, id?.toString()], path, {
    query: {
      enabled: !!id,
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnWindowFocus: true,
    },
  })
}
export const useCreateContact = (contactParty: ContactParty = ContactParty.vendor) => {
  const cache = useQueryClient()
  const path = generatePath(PATHS.contacts, {
    contactParty: getContactPartyUrlPart(contactParty),
  })
  return useCreate<Contact, Contact>(path, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.Contacts, contactParty])
      },
    },
  })
}

type UpdateBody = Contact & {
  contactId: number
}
export const useUpdateContact = (contactParty: ContactParty = ContactParty.vendor) => {
  const cache = useQueryClient()
  return useMutation<Contact, unknown, UpdateBody>(async ({ contactId, ...body }) => {
    const path = generatePath(PATHS.contactItem, {
      contactParty: getContactPartyUrlPart(contactParty),
      id: contactId || '',
    })
    const res = (await api.patch(path, body)).data
    cache.removeQueries([AccountingKeys.Contacts, contactId?.toString()])
    return res
  })
}

export const useDeleteContact = (contactParty: ContactParty = ContactParty.vendor) => {
  const cache = useQueryClient()
  return useDelete<Optional<string | number>>(
    (id) => {
      const path = generatePath(PATHS.contactItem, {
        contactParty: getContactPartyUrlPart(contactParty),
        id: id || '',
      })
      return path
    },
    {
      mutation: {
        onSuccess: () => {
          cache.invalidateQueries([AccountingKeys.Contacts, contactParty])
        },
      },
    },
  )
}

export const useGetFinancialDetails = (
  contactId: number | null = null,
  contactParty: ContactParty = ContactParty.vendor,
) => {
  const path = generatePath(PATHS.contactFinDetails, {
    contactParty: getContactPartyUrlPart(contactParty),
    contactId: contactId || '',
  })
  return useGetList<Finance>([AccountingKeys.Finance, contactId], path, {
    query: {
      enabled: !!contactId,
    },
  })
}

type FinDetailsRequest = { contactId: number; data?: Finance; id?: number }
export const useCreateFinancialDetails = (contactParty: ContactParty = ContactParty.vendor) => {
  return useMutation<Finance, unknown, FinDetailsRequest>(async ({ data, contactId }: FinDetailsRequest) => {
    const path = generatePath(PATHS.contactFinDetails, {
      contactParty: getContactPartyUrlPart(contactParty),
      contactId: contactId,
    })
    return (await api.post(path, data)).data
  })
}

export const useUpdateFinancialDetails = (contactParty: ContactParty = ContactParty.vendor) => {
  return useMutation<unknown, unknown, FinDetailsRequest>(async ({ data, contactId, id }: FinDetailsRequest) => {
    const path = generatePath(PATHS.contactFinDetailsItem, {
      contactParty: getContactPartyUrlPart(contactParty),
      contactId: contactId,
      id,
    })
    return (await api.patch(path, data)).data
  })
}

export const useDeleteFinancialDetails = (contactParty: ContactParty = ContactParty.vendor) => {
  return useMutation<unknown, unknown, FinDetailsRequest>(async ({ contactId, id }: FinDetailsRequest) => {
    const path = generatePath(PATHS.contactFinDetailsItem, {
      contactParty: getContactPartyUrlPart(contactParty),
      contactId: contactId,
      id,
    })
    return (await api.delete(path)).data
  })
}

export const useImportVendorsFromCsv = (orgId?: number | string, party?: ContactParty) => {
  const cache = useQueryClient()
  const fileUpload: MutationFunction<string, File> = async (file: File) => {
    const form = new FormData()
    form.append('file', file)

    const res: AxiosResponse<string> = await api.post(
      `${PATHS.organizations}/${orgId}/import/${getContactPartyUrlPart(party || ContactParty.vendor)}`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return res.data
  }

  return useMutation({
    mutationFn: fileUpload,
    onSuccess: () => {
      cache.invalidateQueries([AccountingKeys.Contacts, party])
    },
  })
}

export const useImportVendorsFromAccountingSoftware = (
  orgId?: number | string,
  contact?: string,
  party?: ContactParty,
) => {
  const cache = useQueryClient()
  return useMutation({
    mutationFn: async () => {
      return (await api.get(`${PATHS.organizations}/${orgId}/sync-${party}s/${contact}`)).data
    },
    mutationKey: [AccountingKeys.VendorIntegrationSync],
    onSuccess: () => {
      cache.invalidateQueries([AccountingKeys.Contacts, party])
    },
  })
}

export const useVendorExport = (contactParty: ContactParty = ContactParty.vendor) => {
  return useMutation(
    async (params: { type: 'csv' | 'xlsx'; organization?: number | string }) => {
      const response = await api.get(
        generatePath(PATHS.useVendorExport, { contactParty: getContactPartyUrlPart(contactParty) }),
        {
          params: params,
          responseType: 'blob',
        },
      )

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${contactParty}s_${new Date().toISOString()}.${params.type}`)
      document.body.appendChild(link)
      link.click()

      return null
    },
    {
      mutationKey: [AccountingKeys.VendorExport, contactParty],
    },
  )
}

export const useGetContracts = (filters: ContractFiltersRequest, enabled = true) => {
  const query = new URLSearchParams(JSON.parse(JSON.stringify(filters)))

  return useGetOne<ContractLineItemsResponse>([AccountingKeys.Contracts, query.toString()], PATHS.contracts, {
    query: {
      enabled,
      keepPreviousData: true,
    },
    axios: {
      params: filters,
    },
  })
}
export const useGetContractFilters = () => {
  return useGetOne<ContractFiltersResponse>([AccountingKeys.ContractFilters], PATHS.filters)
}

export const useGetContract = (id?: number | string | null) =>
  useGetOne<ContractResponse>([AccountingKeys.Contracts, id], `${PATHS.contracts}/${id}`, {
    query: {
      enabled: !!id,
    },
  })

export const useCreateContract = () => useCreate<ContractRequest>(PATHS.contracts)

export const useUpdateContract = (id?: number | string | null) => {
  const cache = useQueryClient()

  return usePatch<ContractRequest>(`${PATHS.contracts}/${id}`, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.Contracts, id])
      },
    },
  })
}

export const useDeleteContract = (id?: string | number) => {
  const cache = useQueryClient()
  return useDelete(`${PATHS.contracts}/${id}`, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.Contracts])
      },
    },
  })
}

export const useTerminateContract = () => {
  const cache = useQueryClient()
  return useCreate((id) => generatePath(PATHS.terminateContract, { id }), {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.Contracts])
      },
    },
  })
}

export const useGetContractReminder = () =>
  useGetOne<ContractReminder>([AccountingKeys.ContractsReminder], PATHS.contractsReminder)

export const useUpdateContractReminder = () => useUpdate<ContractReminder>(PATHS.contractsReminder)

export const useGetContractTypes = (enabled = true) =>
  useGetList<ContractTypeListItemResponse>([AccountingKeys.ContractTypes], PATHS.contractTypes, {
    query: {
      enabled,
    },
  })

export const useCreateContractType = () => {
  const cache = useQueryClient()
  return useCreate<ContractTypeRequest, ContractTypeResponse>(PATHS.contractTypes, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.ContractTypes])
      },
    },
  })
}
export const useUpdateContractType = (id?: number) => {
  const cache = useQueryClient()
  return usePatch<ContractTypeRequest, ContractTypeResponse>(`${PATHS.contractTypes}/${id}`, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.ContractTypes])
      },
    },
  })
}
export const useDeleteContractType = (id: number) => {
  const cache = useQueryClient()
  return useDelete(`${PATHS.contractTypes}/${id}`, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.ContractTypes])
      },
    },
  })
}
